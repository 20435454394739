import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import NavTabsLink from '@Components/ui/nav-tabs-link';
import { getServiceConfigUrl } from '@Utils/navigate';

class ServiceSubNav extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    locationFeatures: PropTypes.object.isRequired
  };

  getSections = (showJsonPrefs, isAddon, isClass) => [
    { navId: 'settings', name: 'Inställningar' },
    !isAddon && !isClass && { navId: 'addons', name: 'Tilläggstjänster' },
    { navId: 'custom-fields', name: 'Egna fält' },
    !isAddon && !isClass && { navId: 'schedule', name: 'Schema', news: true },
    showJsonPrefs && { navId: 'prefs', name: 'Prefs' }
  ].filter(s => s);

  render() {
    const { showJsonPrefs, routeParams, service } = this.props;
    const isAddon = service && service.addon;
    const isClass = service && service.bookingType === 'ClassBooking';
    const sections = this.getSections(showJsonPrefs, isAddon, isClass);

    if (service && !sections.find(s => s.navId === routeParams.tab)) {
      return <Redirect to={getServiceConfigUrl(routeParams, service.id)} />;
    }

    return (
      <NavTabsLink
        subUrl={`admin/${routeParams.subsection}`}
        routeParams={routeParams}
        sections={sections}
      />
    );
  }
}

const mapStateToProps = (state, { routeParams }) => {
  const { locationFeatures, servicesById } = state;
  const serviceId = parseInt(routeParams.id);
  const service = servicesById.get(serviceId);

  return {
    locationFeatures,
    service
  };
};

export default connect(mapStateToProps)(ServiceSubNav);
