import moment from 'moment';
import React, { useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { change, Field, getFormValues, reduxForm } from 'redux-form';
import SelectField from '@Components/inputs/select-field';
import Button from '@Components/ui/button';
import SubmitButton from '@Components/ui/submit-button';
import { getRruleDays } from './recurring-util';

const weeklyIntervals = [
  { value: 1, label: 'Varje vecka' },
  { value: 2, label: 'Varannan vecka' },
  { value: 3, label: 'Var 3:e vecka' },
  { value: 4, label: 'Var 4:e vecka' }
];

const dailyIntervals = [
  { value: 1, label: 'Varje dag' },
  { value: 2, label: 'Varannan dag' },
  { value: 3, label: 'Var 3:e dag' },
  { value: 4, label: 'Var 4:e dag' }
];

const BookingRecurringForm = ({
  handleSubmit = null, onClose = null, submitting = false,
  repeat = 'WEEKLY', days = [], end = 'UNTIL'
}) => {
  const dispatch = useDispatch();
  const rruleDays = useMemo(getRruleDays, []);

  const toggleDay = (day) => {
    const newDays = days.includes(day)
      ? days.filter(d => d !== day)
      : [...days, day];
    dispatch(change('bkf-recurring', 'days', newDays));
  };

  const handleDayClick = (ev, day) => {
    ev.preventDefault();
    toggleDay(day);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row tight">
        <Field
          name="repeat"
          label="Repetera"
          className="col-xs-6"
          component={SelectField}
          autoFocus
        >
          <option value="WEEKLY">Veckovis</option>
          <option value="DAILY">Dagligen</option>
        </Field>
        <div className="form-group col-xs-6">
          <label className="control-label">Intervall</label>
          <div className="select-container">
            <Field name="interval" component="select" className="form-control">
              {repeat === 'WEEKLY' && weeklyIntervals.map(({ value, label }) => (
                <option key={value} value={value}>{label}</option>
              ))}
              {repeat === 'DAILY' && dailyIntervals.map(({ value, label }) => (
                <option key={value} value={value}>{label}</option>
              ))}
            </Field>
          </div>
        </div>
      </div>
      {repeat === 'WEEKLY' && (
        <div className="row tight">
          <div className="form-group col-xs-12">
            <label className="control-label">Veckodagar</label>
            <div className="btn-group">
              {rruleDays.map(({ day, name }) => (
                <button
                  key={day}
                  className={days.includes(day) ? 'btn btn-primary' : 'btn btn-default'}
                  onClick={(ev) => handleDayClick(ev, day)}
                >
                  {name}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="row tight">
        <Field
          name="end"
          label="Slutar"
          className="col-xs-6"
          component={SelectField}
        >
          <option value="UNTIL">Vid datum</option>
          <option value="COUNT">Efter antal</option>
        </Field>
        <div className="form-group col-xs-6">
          <label className="control-label">&nbsp;</label>
          {end === 'UNTIL' && (
            <Field
              name="until"
              component="input"
              type="date"
              className="form-control"
              min={moment().format('L')}
              required
            />
          )}
          {end === 'COUNT' && (
            <Field
              name="count"
              component="input"
              className="form-control"
              required
            />
          )}
        </div>
      </div>
      {handleSubmit && onClose && (
        <div className="text-right mt2">
          <Button small gray marginRight onClick={onClose}>Avbryt</Button>
          <SubmitButton submitting={submitting} onClick={handleSubmit}>Spara</SubmitButton>
        </div>
      )}
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    ...getFormValues('bkf-recurring')(state)
  };
};

export default connect(mapStateToProps)(reduxForm({
  form: 'bkf-recurring',
  destroyOnUnmount: false
})(BookingRecurringForm));
