import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { resourceTypes } from '@Utils/resource-util';
import { getChangeJsonPrefsPermission, getFeatures } from '@State/selectors';
import NavTabsLink from '@Components/ui/nav-tabs-link';
import { getResourceConfigUrl } from '@Utils/navigate';

class ResourceSubNav extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    locationFeatures: PropTypes.object.isRequired
  };

  sections() {
    const { resource, features, canChangeJsonPrefs } = this.props;
    const { ShowGeneralSettings, ShowServicesSettings } = features;
    const isPerson = resource?.resourceType === resourceTypes.Person;
    const sections = [];

    if (ShowGeneralSettings) {
      sections.push({ navId: 'settings', name: 'Inställningar' });
    }
    if (ShowServicesSettings && isPerson) {
      sections.push({ navId: 'services', name: 'Tjänster' });
    }
    if (canChangeJsonPrefs) {
      sections.push({ navId: 'prefs', name: 'Prefs' });
    }
    return sections;
  }

  render() {
    const { routeParams, resource } = this.props;
    const sections = this.sections();

    if (resource && !sections.find(s => s.navId === routeParams.tab)) {
      return <Redirect to={getResourceConfigUrl(routeParams, resource.id)} />;
    }

    return (
      <NavTabsLink
        subUrl="admin/resources"
        routeParams={routeParams}
        sections={sections}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    canChangeJsonPrefs: getChangeJsonPrefsPermission(state, ownProps),
    resource: ownProps.resource,
    features: getFeatures(state)
  };
};

export default connect(mapStateToProps)(ResourceSubNav);
