import React from 'react';

const ConfirmCloseClass = ({ onCancel, onConfirm, progress }) => {
  return (
    <form autoComplete="off" onSubmit={onConfirm} className={progress ? 'disabled' : ''}>
      <p className="mb3">
        <strong>Vill du stänga detta pass för bokning?</strong>
      </p>

      <div className="text-right">
        <button type="button" className="btn btn-default" tabIndex={2} onClick={onCancel}>Avbryt</button> &nbsp;
        <button type="submit" className="btn-save" tabIndex={1}>{progress ? 'Stänger...' : 'Stäng bokning'}</button>
      </div>
    </form>
  );
};

export default ConfirmCloseClass;
