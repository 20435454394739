import React, { useState } from 'react';
import Loader from '@Components/ui/loader';
import ProgressButton from '@Components/ui/progress-button';
import { useFetchBookingSeries } from './booking-form-series-hook';
import ConfirmCancel from './confirm-cancel';

const ConfirmUnpublishSeries = ({ onCancel, onConfirm, progress }) => {
  const { loading, hasCustomers } = useFetchBookingSeries();
  const [options, setOptions] = useState(null);

  const handleConfirm = (ev) => {
    ev?.preventDefault();
    return onConfirm({
      cancelBookings: hasCustomers,
      ...options
    });
  };

  if (hasCustomers && !options) {
    return (
      <ConfirmCancel
        onConfirm={(values) => setOptions(values)}
        onCancel={onCancel}
        isUnpublishClass
      />
    );
  }

  return (
    <form autoComplete="off" onSubmit={handleConfirm} className={progress ? 'disabled' : ''}>
      {loading && <Loader />}
      <p className="mb3">
        <strong>Bekräfta avpublicering av samtliga pass i serien.</strong>
      </p>

      {hasCustomers && (
        <p className="text-info mb2">
          <i className="fa fa-exclamation-circle mr1" />
          Observera att det finns bokningar i serien
        </p>
      )}

      <div className="text-right">
        <button type="button" className="btn btn-default" tabIndex={2} onClick={onCancel}>Avbryt</button> &nbsp;
        <ProgressButton small primary type="submit" onClick={handleConfirm} tabIndex={1}>Avpublicera</ProgressButton>
      </div>
    </form>
  );
};

export default ConfirmUnpublishSeries;
