import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bkfModals } from '@State/bkf/constants';
import { toggleBKFModal } from '@State/bkf/actions';
import { txt } from '@Utils/i18n-util';
import { isBookingNew, getClassStatus } from '@Utils/booking-util';

import msg from './booking-class-info.msg';

class BookingClassInfo extends Component {
  handleOpen = (ev) => {
    ev.preventDefault();
    this.props.onShowClass();
  };

  render() {
    const { bookedSlots, maxSlots, classStatus, published, services } = this.props;
    const hasServices = services && services.size > 0;
    const isNew = isBookingNew(this.props);
    const status = getClassStatus(bookedSlots, maxSlots, classStatus, published);
    const canOpen = published || bookedSlots > 0;

    if (isNew || !hasServices) {
      return null;
    }

    return (
      <div className={canOpen ? 'booking-form-block top-part' : 'booking-form-block top-part cancelled'} onClick={canOpen ? this.handleOpen : null}>
        <div className="block-buttons">
          {canOpen && (
            <button className="btn-label" tabIndex={-1} onClick={this.handleOpen}>{txt(msg.openBtnLabel)}</button>
          )}
        </div>
        <h4>
          <span className="color-dot" style={{ background: status.color }} />
          {status.description}
        </h4>
        <div>{bookedSlots || 0}/{maxSlots} {txt(msg.bookedSlots)}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf } = state;
  const series = bkf.get('bookingSeries');

  return {
    id: bkf.get('id'),
    published: series?.published,
    bookedSlots: bkf.get('bookedSlots'),
    classStatus: bkf.get('classStatus'),
    maxSlots: bkf.get('maxSlots'),
    services: bkf.get('services'),
    service: bkf.get('service'),
    customers: bkf.get('customers')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowClass: () => dispatch(toggleBKFModal(bkfModals.class, true))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingClassInfo);
