import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getSmsEnabled } from '@State/selectors';
import { sms, email as emailKeys, web } from '@Utils/preference-keys';
import PhoneUtil from '@Utils/phone-util';
import { txt } from '@Utils/i18n-util';
import msg from './confirm-cancel.msg';

const ConfirmCancel = ({
  onCancel, onConfirm, progress, customer, customerIds, isUnpublishClass
}) => {
  const smsEnabled = useSelector(getSmsEnabled);
  const locationConfig = useSelector(state => state.locationConfig);
  const startTime = useSelector(state => state.bkf.get('startTime'));
  const payments = useSelector(state => state.bkf.get('payments'));

  const cancelUpToMinutes = locationConfig.get(web.cancelUpToMinutes);
  const defaultOptionSmsConfirm = locationConfig.get(sms.defaultOptionSmsConfirm);
  const defaultOptionEmailConfirm = locationConfig.get(emailKeys.defaultOptionEmailConfirm);

  const { phoneNumber, email } = customer || {};
  const isMultipleCustomers = customerIds?.length > 0 || isUnpublishClass;
  const canSendConfirmation = (phoneNumber && smsEnabled) || email || isMultipleCustomers;

  const lastCancellationTime = moment(startTime).subtract(cancelUpToMinutes, 'minutes');
  const showOverrideCancellationPolicy = lastCancellationTime.isBefore(moment()) && !isUnpublishClass;
  const onlinePayment = payments?.find(p => p.paymentType === 'Online' && p.paymentStatus === 'Paid');

  const handleConfirm = (ev) => {
    ev.preventDefault();
    const form = ev.target;
    onConfirm({
      notifyBySms: form.notifyBySms?.checked,
      notifyByEmail: form.notifyByEmail?.checked,
      deleteBooking: form.deleteBooking?.checked,
      overrideCancellationPolicy: form.overrideCancellationPolicy?.checked
    });
  };

  return (
    <form autoComplete="off" onSubmit={handleConfirm} className={progress ? 'disabled' : ''}>
      <p>
        <strong>{txt(msg.txtConfirmCancel)}</strong>
      </p>
      {showOverrideCancellationPolicy && (
        <div className="mb3">
          <p>
            <em>{txt(msg.txtOverrideCancellation)}</em>
          </p>
          {onlinePayment && (
            <div className="checkbox">
              <label>
                <input type="checkbox" name="overrideCancellationPolicy" value="true" defaultChecked /> {txt(msg.lblRefundPayment)}
              </label>
            </div>
          )}
        </div>
      )}

      {canSendConfirmation && (
        <div>
          <p>
            <strong>{txt(msg.lblSendConfirmation)}</strong>
          </p>
          {(isMultipleCustomers || phoneNumber) && smsEnabled && (
            <div className="checkbox">
              <label>
                <input type="checkbox" name="notifyBySms" value="true" defaultChecked={defaultOptionSmsConfirm} />{' '}
                {PhoneUtil.formatPhoneNumber(phoneNumber) || 'SMS'}
              </label>
            </div>
          )}
          {(isMultipleCustomers || email) && (
            <div className="checkbox">
              <label>
                <input type="checkbox" name="notifyByEmail" value="true" defaultChecked={defaultOptionEmailConfirm} />{' '}
                {email || 'Email'}
                <br />
              </label>
            </div>
          )}
        </div>
      )}
      {!isMultipleCustomers && (
        <div className="checkbox">
          <label>
            <input type="checkbox" name="deleteBooking" value="true" /> <span className="text-danger">{txt(msg.lblDeleteBooking)}</span>
          </label>
        </div>
      )}
      <div className="text-right">
        <button type="button" className="btn btn-default" tabIndex={4} onClick={onCancel}>{txt(msg.btnCancel)}</button> &nbsp;
        <button type="submit" className="btn-save" tabIndex={3}>{progress ? txt(msg.btnCancellingProgress) : txt(msg.btnConfirm)}</button>
      </div>
    </form>
  );
};

export default ConfirmCancel;
