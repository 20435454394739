var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { omit } from 'ramda';
import Immutable from 'immutable';
import moment from 'moment';
import { isClassBooking } from '@Utils/booking-util';
import { CUSTOMER_UPDATED } from '@State/customer-actions';
import { ADD_BOOKING, CANCEL_BOOKING, CHANGE_BOOKING, CHANGE_CLASS_BOOKING, CHANGE_BOOKING_ATTRIBUTE, CHANGE_BOOKING_CUSTOM_FIELDS, CHANGE_BOOKING_STATUS, CHANGE_BOOKING_TYPE, CLEAR_BOOKINGS, CONFIRM_BOOKING, DELETE_BOOKING, IS_SEARCH_BOOKINGS, MOVE_BOOKING, REFUND_BOOKING, REQUEST_BOOKINGS, RESET_SEARCH, SET_SEARCH_SCROLL_POS, REVERT_BOOKING, SEARCH_BOOKINGS, SET_BOOKINGS, SHOW_SEARCH, PRE_PAYMENT_ADDED, CHANGE_BOOKING_SERIES, DELETE_BOOKING_SERIES, CANCEL_BOOKING_SERIES } from './booking-actions';
import { CLEAR_VIEWDATA, RECEIVE_VIEWDATA } from './view-actions';
import { ADD_BOOKING_TO_CLIPBOARD, ADD_CLIPBOARD_DRAGGER, PASTE_BOOKING, REMOVE_BOOKING_FROM_CLIPBOARD, REMOVE_CLIPBOARD_DRAGGER } from './clipboard-actions';
import { BOOKING_CUSTOMER_ADDED, BOOKING_CUSTOMER_REMOVED } from './bkf/constants';
import { CLEAR_LOCATION_STATE } from './account-actions';
import { SET_FINDTIME_SLOTS } from './find-time/constants';
import { getBookingFromSlot } from './find-time/actions';
const valueOrExisting = (value, existing) => {
    return typeof value !== 'undefined' ? value : existing;
};
export function bookingsById(state = Immutable.Map({}), action = null) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    switch (action.type) {
        case CLEAR_LOCATION_STATE:
        case CLEAR_BOOKINGS:
        case CLEAR_VIEWDATA:
            return state.clear();
        case CHANGE_BOOKING_ATTRIBUTE: {
            const { bookingId, customerIds, attributes } = action.change;
            const booking = state.get(bookingId);
            if (!booking) {
                console.error(`No booking found for id: ${bookingId}`);
                return state;
            }
            const newCustomers = (_a = booking.customers) === null || _a === void 0 ? void 0 : _a.map(customer => {
                return customerIds.includes(customer.customerId)
                    ? Object.assign(Object.assign({}, customer), { bookingAttributes: Object.assign(Object.assign({}, customer.bookingAttributes), attributes) }) : customer;
            });
            const newAttributes = Object.assign(Object.assign({}, booking.attributes), attributes);
            const newBooking = isClassBooking(booking)
                ? Object.assign(Object.assign({}, booking), { customers: newCustomers }) : Object.assign(Object.assign({}, booking), { customers: newCustomers, attributes: newAttributes });
            return state.set(bookingId, newBooking);
        }
        case CHANGE_BOOKING_STATUS: {
            const { bookingId, customerIds, status } = action.change;
            const booking = state.get(bookingId);
            if (!booking) {
                console.error(`No booking found for id: ${bookingId}`);
                return state;
            }
            const newCustomers = (_b = booking.customers) === null || _b === void 0 ? void 0 : _b.map(customer => {
                return customerIds.includes(customer.customerId)
                    ? Object.assign(Object.assign({}, customer), { status }) : customer;
            });
            const newBooking = isClassBooking(booking)
                ? Object.assign(Object.assign({}, booking), { customers: newCustomers }) : Object.assign(Object.assign({}, booking), { customers: newCustomers, status });
            return state.set(bookingId, newBooking);
        }
        case CHANGE_BOOKING_TYPE: {
            const bkId = action.change.bookingId, { change } = action;
            const booking = state.get(bkId);
            if (!booking) {
                console.error(`No booking found for id: ${bkId}`);
                return state;
            }
            let newBooking = Object.assign(Object.assign({}, booking), { type: change.type });
            // Strip all props that's not part of a Reservation
            //
            if (change.type === 'Reservation') {
                newBooking = omit([
                    'customers', 'services', 'description', 'serviceDuration', 'afterTime'
                ], newBooking);
            }
            return state.set(bkId, newBooking);
        }
        case BOOKING_CUSTOMER_ADDED: {
            const { bookingId, customer } = action;
            const booking = state.get(bookingId);
            if (!booking) {
                return state;
            }
            const existing = (_c = booking.customers) !== null && _c !== void 0 ? _c : [];
            const customers = [...existing, customer];
            const newBooking = Object.assign(Object.assign({}, booking), { customers });
            if (isClassBooking(booking)) {
                newBooking.bookedSlots += 1;
            }
            return state.set(bookingId, newBooking);
        }
        case BOOKING_CUSTOMER_REMOVED: {
            const { bookingId, customerId } = action;
            const booking = state.get(bookingId);
            if (!booking) {
                return state;
            }
            const customers = (_d = booking.customers) === null || _d === void 0 ? void 0 : _d.filter(c => c.customerId !== customerId);
            const newBooking = Object.assign(Object.assign({}, booking), { customers });
            if (isClassBooking(booking)) {
                newBooking.bookedSlots -= 1;
            }
            return state.set(bookingId, newBooking);
        }
        case CANCEL_BOOKING: {
            const { id: bookingId, customerIds, changes } = action;
            const booking = state.get(bookingId);
            if (!booking) {
                console.error(`No booking found for id: ${bookingId}`);
                return state;
            }
            const newCustomers = (_e = booking.customers) === null || _e === void 0 ? void 0 : _e.map(customer => {
                return customerIds.includes(customer.customerId)
                    ? Object.assign(Object.assign({}, customer), changes) : customer;
            });
            const bookedSlots = booking.bookedSlots - customerIds.length;
            const newBooking = isClassBooking(booking)
                ? Object.assign(Object.assign({}, booking), { customers: newCustomers, bookedSlots }) : Object.assign(Object.assign(Object.assign({}, booking), changes), { customers: newCustomers });
            return state.set(bookingId, newBooking);
        }
        case REFUND_BOOKING: {
            const bkId = action.id;
            const booking = state.get(bkId);
            if (!booking) {
                console.error(`No booking found for id: ${bkId}`);
                return state;
            }
            const onlinePayment = (_f = booking.payments) === null || _f === void 0 ? void 0 : _f.find(p => p.paymentType === 'Online');
            if (!onlinePayment) {
                return state;
            }
            const payments = [...booking.payments.filter(p => p.paymentType !== 'Online'), Object.assign(Object.assign({}, onlinePayment), { paymentStatus: 'Refunded', refundTs: moment(action.refund.created) })];
            const newBooking = Object.assign(Object.assign({}, booking), { payments });
            return state.set(bkId, newBooking);
        }
        case ADD_BOOKING: {
            const bookingToAdd = Object.assign(Object.assign(Object.assign({}, action.booking), datesAsMoments(action.booking)), calcProperties(action.booking));
            return state.set(action.booking.id, bookingToAdd);
        }
        case PASTE_BOOKING: {
            return action.booking.copyOnPaste
                ? state
                : state.set(action.booking.id, Object.assign(Object.assign({}, action.booking), datesAsMoments(action.booking)));
        }
        case CONFIRM_BOOKING: {
            const bkId = action.id;
            const { payments, sales, attributes } = action.booking;
            const booking = state.get(bkId);
            if (!booking) {
                console.error(`No booking found for id: ${bkId}`);
                return state;
            }
            const otherCustomers = booking.customers
                .filter(c => c.customerBookingId !== action.customer.customerBookingId);
            const newBooking = Object.assign(Object.assign({}, booking), { status: action.customer.status, customers: [...otherCustomers, action.customer], payments,
                sales,
                attributes });
            return state.set(bkId, newBooking);
        }
        case DELETE_BOOKING: {
            return state.delete(action.id);
        }
        case CHANGE_BOOKING: {
            const bkId = action.id;
            const { services, description, startTime, endTime, afterTime, note, resources, reservationType, price, priceFrom } = action.booking;
            const booking = state.get(bkId);
            if (!booking) {
                console.error(`No booking found for id: ${bkId}`);
                return state;
            }
            const newBooking = Object.assign(Object.assign({}, booking), { services: valueOrExisting(services, booking.services), resources: valueOrExisting(resources, booking.resources), description: valueOrExisting(description, booking.description), startTime: moment(valueOrExisting(startTime, booking.startTime)), endTime: moment(valueOrExisting(endTime, booking.endTime)), afterTime: valueOrExisting(afterTime, booking.afterTime), note: valueOrExisting(note, booking.note), reservationType: valueOrExisting(reservationType, booking.reservationType), price: valueOrExisting(price, booking.price), priceFrom: valueOrExisting(priceFrom, booking.priceFrom) });
            return state.set(bkId, newBooking);
        }
        case CHANGE_BOOKING_CUSTOM_FIELDS: {
            const { bookingId, customerIds, customFields } = action;
            const booking = state.get(bookingId);
            if (!booking) {
                console.error(`No booking found for id: ${bookingId}`);
                return state;
            }
            const newCustomers = (_g = booking.customers) === null || _g === void 0 ? void 0 : _g.map(customer => {
                return customerIds.includes(customer.customerId)
                    ? Object.assign(Object.assign({}, customer), { customFields }) : customer;
            });
            const newBooking = Object.assign(Object.assign({}, booking), { customFields, customers: newCustomers });
            return state.set(bookingId, newBooking);
        }
        case CHANGE_CLASS_BOOKING: {
            const bkId = action.id;
            const { maxSlots, bookedSlots, classStatus } = action;
            const booking = state.get(bkId);
            if (!booking) {
                console.error(`No booking found for id: ${bkId}`);
                return state;
            }
            const newBooking = Object.assign(Object.assign({}, booking), { maxSlots: valueOrExisting(maxSlots, booking.maxSlots), bookedSlots: valueOrExisting(bookedSlots, booking.bookedSlots), classStatus: valueOrExisting(classStatus, booking.classStatus) });
            return state.set(bkId, newBooking);
        }
        case CHANGE_BOOKING_SERIES: {
            const { seriesId, published } = action;
            return state.withMutations((map) => {
                state.forEach((booking) => {
                    var _a;
                    const series = booking.bookingSeries;
                    if (((_a = booking.bookingSeries) === null || _a === void 0 ? void 0 : _a.seriesId) === seriesId) {
                        map.set(booking.id, Object.assign(Object.assign({}, booking), { bookingSeries: Object.assign(Object.assign({}, series), { published: valueOrExisting(published, series.published) }) }));
                    }
                });
            });
        }
        case CANCEL_BOOKING_SERIES: {
            const { seriesId, changes } = action;
            return state.withMutations((map) => {
                map.filter(b => { var _a; return ((_a = b.bookingSeries) === null || _a === void 0 ? void 0 : _a.seriesId) === seriesId; })
                    .forEach((booking) => {
                    var _a;
                    const customers = (_a = booking.customers) === null || _a === void 0 ? void 0 : _a.map((customer) => {
                        return customer.status !== 'Cancelled'
                            ? Object.assign(Object.assign({}, customer), changes) : customer;
                    });
                    map.set(booking.id, Object.assign(Object.assign({}, booking), { bookedSlots: 0, customers }));
                });
            });
        }
        case DELETE_BOOKING_SERIES: {
            const { seriesId } = action;
            return state.withMutations((map) => {
                state.forEach((booking) => {
                    var _a;
                    if (((_a = booking.bookingSeries) === null || _a === void 0 ? void 0 : _a.seriesId) === seriesId) {
                        map.delete(booking.id);
                    }
                });
            });
        }
        case MOVE_BOOKING: {
            const { bookings, resources } = action.moveEvent;
            const resourceId = (_h = resources === null || resources === void 0 ? void 0 : resources.find(r => r.primary)) === null || _h === void 0 ? void 0 : _h.id;
            return state.withMutations((map) => {
                bookings.forEach(({ id, startTime, endTime }) => {
                    const booking = state.get(id);
                    if (!booking) {
                        return;
                    }
                    const newBooking = Object.assign(Object.assign({}, booking), { resources: (resources === null || resources === void 0 ? void 0 : resources.length) > 0 ? resources : booking.resources, resourceId: resourceId || booking.resourceId, startTime: moment(startTime), endTime: moment(endTime), undone: action.isUndo });
                    map.set(id, newBooking);
                });
            });
        }
        case CUSTOMER_UPDATED: {
            const cId = action.id;
            const { name, phoneNumber, otherPhoneNumber, email, officialIdNo, associatedResourceId } = action.customer;
            const bookings = state.filter(b => { var _a; return (_a = b.customers) === null || _a === void 0 ? void 0 : _a.some(c => c.customerId === cId); });
            if (bookings.isEmpty()) {
                // No booking matched the changed customer
                return state;
            }
            return state.withMutations((map) => {
                bookings.forEach((booking) => {
                    // Only update fields that are allowed on a CUSTOMER_UPDATED.
                    const customer = booking.customers.find(c => c.customerId === cId);
                    const newCustomer = Object.assign(Object.assign({}, customer), { name: valueOrExisting(name, customer.name), phoneNumber: valueOrExisting(phoneNumber, customer.phoneNumber), otherPhoneNumber: valueOrExisting(otherPhoneNumber, customer.otherPhoneNumber), email: valueOrExisting(email, customer.email), officialIdNo: valueOrExisting(officialIdNo, customer.officialIdNo), associatedResourceId: valueOrExisting(associatedResourceId, customer.associatedResourceId) });
                    const customers = [
                        ...booking.customers.filter(c => c.customerId !== cId),
                        newCustomer
                    ];
                    map.set(booking.id, Object.assign(Object.assign({}, booking), { customers }));
                });
            });
        }
        case PRE_PAYMENT_ADDED:
            return addExternalPayment(state, action);
        case REVERT_BOOKING:
            return action.bookingId && action.booking
                ? state.set(action.bookingId, Object.assign({}, action.booking))
                : state;
        case REQUEST_BOOKINGS:
            return state;
        case SET_BOOKINGS:
            return setBookings(state, action.bookings);
        case RECEIVE_VIEWDATA:
            return setBookings(state, action.viewData.bookings, action.resources);
        case SET_FINDTIME_SLOTS:
            return action.showInCalendar
                ? addGhostBookings(state, action.slots, action.services, action.resources)
                : state;
        default:
            return state;
    }
}
function setBookings(state, bookings, resources = null) {
    return state.clear().withMutations(map => {
        for (const booking of bookings) {
            map.set(booking.id, Object.assign(Object.assign(Object.assign({}, booking), datesAsMoments(booking)), calcProperties(booking, resources)));
        }
    });
}
function addGhostBookings(state, slots, services, resources) {
    return state.withMutations(map => {
        for (const slot of slots) {
            map.set(slot.key, getBookingFromSlot(slot, services, resources));
        }
    });
}
function addExternalPayment(state, action) {
    return state.withMutations((map) => {
        const { bookingId, sale, payment } = action;
        const _a = state.get(bookingId), { sales = [], payments = [] } = _a, booking = __rest(_a, ["sales", "payments"]);
        const newBooking = Object.assign(Object.assign({}, booking), { payments: [...payments, payment], sales: sale ? [...sales, sale] : sales });
        map.set(bookingId, newBooking);
    });
}
export function bookingSearchResults(state = Immutable.Map({}), action = null) {
    switch (action.type) {
        case RESET_SEARCH:
            return state.clear();
        case SHOW_SEARCH:
            return state.set('showSearch', true);
        case SEARCH_BOOKINGS:
            return state.merge({ query: action.query, bookings: action.bookings });
        case IS_SEARCH_BOOKINGS:
            return state.set('isSearching', action.isSearching);
        case SET_SEARCH_SCROLL_POS:
            return state.set('scrollPos', action.scrollPos);
        default:
            return state;
    }
}
export function clipboardState(state = Immutable.Map({
    clipboardDragger: null
}), action = null) {
    switch (action.type) {
        case CLEAR_LOCATION_STATE:
            return state.clear();
        case ADD_CLIPBOARD_DRAGGER:
            return state.set('clipboardDragger', Object.assign({}, action.dragger));
        case REMOVE_CLIPBOARD_DRAGGER:
            return state.delete('clipboardDragger');
        default:
            return state;
    }
}
export function bookingsClipboard(state = Immutable.OrderedMap({}), action = null) {
    switch (action.type) {
        case CLEAR_LOCATION_STATE:
            return state.clear();
        case ADD_BOOKING_TO_CLIPBOARD:
            return state.set(action.booking.id, Object.assign({}, action.booking));
        case PASTE_BOOKING:
            return state.delete(action.booking.id);
        case REMOVE_BOOKING_FROM_CLIPBOARD: {
            return state.delete(action.bookingId);
        }
        default:
            return state;
    }
}
function calcProperties(bk, resources = null) {
    var _a;
    const resourceId = bk.resourceId || bk.resources.find(r => r.primary).id;
    const resource = resources === null || resources === void 0 ? void 0 : resources.find(r => r.id === resourceId);
    return {
        cancelled: bk.status === 'Cancelled',
        reservation: bk.type === 'Reservation',
        resourceId,
        resourceColor: (_a = resource === null || resource === void 0 ? void 0 : resource.prefs) === null || _a === void 0 ? void 0 : _a.color
    };
}
function datesAsMoments(bk) {
    return {
        startTime: moment.isMoment(bk.startTime)
            ? bk.startTime
            : moment(bk.startTime),
        endTime: moment(bk.endTime),
        createdTime: moment(bk.createdTime),
        lastUpdateTime: moment(bk.lastUpdateTime)
    };
}
